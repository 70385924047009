.terms-of-service {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family:  "Bitter", sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .terms-of-service h1 {
    font-size: 2em;
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .terms-of-service h2 {
    font-size: 1.5em;
    color: #2c3e50;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .terms-of-service p {
    margin-bottom: 15px;
  }
  
  .terms-of-service ul {
    margin-bottom: 15px;
    padding-left: 30px;
  }
  
  .terms-of-service li {
    margin-bottom: 10px;
  }
  
  .terms-of-service strong {
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .terms-of-service {
      padding: 15px;
    }
  
    .terms-of-service h1 {
      font-size: 1.8em;
    }
  
    .terms-of-service h2 {
      font-size: 1.3em;
    }
  }