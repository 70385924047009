.highlight-card {
    position: relative;
    padding: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.highlight-text {
    margin-bottom: 0.5rem;
}

.annotation-container {
    margin-top: 0.5rem;
    padding-right: 2rem; /* Make space for the copy button */
}

.annotation-bubble {
    background-color: #e6fffb;
    border: 1px solid #87e8de;
    border-radius: 8px;
    padding: 0.5rem;
    position: relative;
}

.annotation-bubble::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 20px;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent #87e8de;
}

.annotation-text {
    font-size: 0.9em;
    color: #555;
}

.copy-button {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.highlight-card:hover .copy-button {
    opacity: 1;
}

.copy-button:hover {
    color: var(--button-color);
}

.copy-tooltip {
    position: absolute;
    background-color: var(--button-color);
    color: black;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.copy-button:hover .copy-tooltip {
    opacity: 1;
}