.home {
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px;
  font-family: "Bitter", sans-serif;
  background-color: var(--background-color);
  margin-bottom: 20vh;
}

/* ********** EXPLANATIONS ********** */

.tagline {
  font-size: 1.5em;
  margin-bottom: 20px;
  font-weight: bold;
}

.instructions {
  font-family: "Bitter", sans-serif;
  margin-bottom: 20px;
  line-height: 1.7;
}

.os-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: middle;
}

/********* Dropzone *********/

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone.active {
  border-color: #2196f3;
}

.disclaimer {
  font-size: 0.9em;
  color: #666;
  font-style: italic;
}

/* ********** Show Highlights Button ********** */

.show-highlights {
  margin: 10px auto;
  display: block;
  align-items: center;
  font-size: 1em; 
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: var(--button-color);
  color: black;
}

.show-highlights:hover{
  opacity: 0.7;
}





/* ********** Popup ********** */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 90vw;
  max-height: 86vh;
  overflow-y: auto;
  position: relative;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.hero-image {
  text-align: center;
  margin-bottom: 20px;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  max-height: 50vh; /* Adjust this value as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px; /* Optional: adds rounded corners */
}



@media (min-width: 375px) {
  .home {
    padding-top:0px;
  }
}