footer {
  margin-top: auto;
  background-color: #f8f8f8;
  padding: 15px 0;
  border-top: 1px solid #e7e7e7;
  font-size: 12px; /* Smaller base font size for mobile */
}

.site-footer {
    background-color: #f8f8f8;
    padding: 20px 0;
    border-top: 1px solid #e7e7e7;
  }
  
  .footer-content {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: center;
    padding: 0 15px;
  }
  
  .footer-nav {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: center;
    gap: 15px;
    margin-bottom: 10px; /* Add space between nav and copyright */
  }
  
  .footer-nav a {
    color: #333;
    text-decoration: none;
    font-size: 12px;
    padding: 5px; /* Increase tap target size */
  }
  
  .footer-nav a:hover {
    text-decoration: underline;
  }
  
  .copyright {
    text-align: center;
    font-size: 11px;
    color: #666;
  }

/* Media query for larger screens */
@media (min-width: 768px) {
  footer {
    font-size: 14px; /* Slightly larger font for desktop */
  }

  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    padding: 0 20px;
  }

  .footer-nav {
    margin-bottom: 0;
  }

  .footer-nav a {
    font-size: 14px;
  }

  .copyright {
    font-size: 13px;
  }
}