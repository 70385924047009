/* ********** Highlights Container ********** */

.highlights-container {
    display: flex;
    height: 80vh; /* Adjust as needed */
    width: 100%;
    max-width: 800px; /* Adjust as needed */
    margin: 20px auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    align-items: flex-start;
  }
  
  .highlights-list {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .highlights-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 15px 15px;
  }
  
  .title-and-count {
    display: flex;
    align-items: center;
    width: 70%;
  }

  .title-and-count h2 {
    margin: 0;
  }
  
  .highlight-count {
    font-size: 0.7em;
    font-weight: normal;
    color: var(--text-color-secondary);
    margin-left: 10px;
  }
  
  .highlights-scroll {
    max-height: 90vh; /* Adjust this value as needed */
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }
  
  .highlights-scroll::-webkit-scrollbar {
    width: 8px;
  }
  
  .highlights-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .highlights-scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .highlights-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Ensure smooth scrolling */
  .highlights-scroll {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  
  
  
  .highlight-card {
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 12px 10px 12px;
    white-space: pre-wrap;
    line-height: 1.7;
  }
  
  /* ********** Highlights Container ********** */
  
  
  .header-buttons {
    display: flex;
    gap: 10px;
    width: 30%;
  }
  
  .header-buttons button {
    padding: 5px 10px;
    background-color: var(--button-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .header-buttons button:hover {
    opacity: 0.9;
  }
  
  
  .sort-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .sort-button:hover {
    background-color: #e0e0e0;
  }
  
  .sort-button.asc {
    background-color: #fff7e6;
    border-color: #91d5ff;
  }
  
  .sort-button.desc {
    background-color: #fff7e6;
    border-color: #ffd591;
  }
  
  .sort-button svg {
    font-size: 1.2em;
  }
  
  