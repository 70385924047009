.contact-page {
    width: 60vw;
    margin: 0 auto;
    padding: 20px;
    font-family: "Bitter", sans-serif;
    background-color: var(--background-color);
    margin-bottom: 20vh;
  }
  

  
  .contact-info {
    margin-top: 30px;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
  }
  

  
  a {
    color: #0066cc;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }