.about-page {
    margin: 0 auto;
    padding: 20px;
    font-family: "Bitter", sans-serif;
    background-color: var(--background-color);
    margin-bottom: 20vh;
    
}

.about-page p {
  line-height: 1.5;
}

.about-page-title {
    margin: 20px 0 10px 0;
}

.hero-image img {
  max-width: 100%;
  height: auto;
}

.accordion-wrapper {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.accordion-question {
  padding: 15px;
  background-color: #f5f5f5;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.accordion-question:hover {
  background-color: #e0e0e0;
}

.accordion-question.open {
  background-color: #e0e0e0;
}

.accordion-icon {
  margin-left: 10px;
}

.accordion-answer {
  padding: 15px;
  background-color: #ffffff;
  line-height: 1.5;
}
