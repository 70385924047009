  /* ********** Highlights Container ********** */
  
  .notes-container {
    display: flex;
    height: 80vh; /* Adjust as needed */
    width: 100%;
    max-width: 73vw; /* Adjust as needed */
    margin: 20px auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    align-items: flex-start;
  }
  
 
  
  
  