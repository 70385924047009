/* ********** Tabs ********** */

.books {
    width: 30%;
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid #ccc;
  }
  
  .book {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .book.active {
    background-color: #708871;
    color: white;
  }