/* ********** GLOBAL CSS ********** */

:root {
  --background-color: #FEF3E2;
  --primary-text: #000000;
  --secondary-text: #8a7fff;
  --button-color: #BEC6A0;
  --input-border: #d0d0d0;
  --task-border: #e0e0e0;
  --checkbox-border: #a0a0a0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


h1 {
  font-size: 2.5em;
  margin: 0;
}

body { 
  font-family: "Bitter", sans-serif;
  background-color: var(--background-color);
}

button {
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Bitter", sans-serif;
}

header {
  font-family: "Bitter", sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}



/* ********** App ********** */



.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.App {
  /* text-align: center; */
  font-family: "Bitter", sans-serif;
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}



.home, .faq-page, .legal-page, .about-page {
  flex: 1;
  padding: 20px;
}



/* ********** nav bar or header ********** */

.logo-container {
  display: flex; 
  flex-direction: row;
  margin: 0px 40px 0px 20px;
}

.nav-bar {
  background-color: #606676;
  padding: 10px 0;
  color: white;
}

.nav-bar nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.nav-bar nav ul li {
  margin: 0 10px;
}

.nav-bar nav ul li a {
  color: white;
  text-decoration: none;
}

.nav-bar nav ul li a:hover {
  text-decoration: underline;
}

.logo-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.logo-link:hover,
.logo-link:visited,
.logo-link:active {
  text-decoration: none;
  color: inherit;
}



@media (max-width: 415px) {
  .site-title {
    font-size: 1.5em;
  }

  .nav-bar {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .logo-container {
    margin: 0px 40px 0px 10px;
  }

  .logo {
    margin-right: 0px;
  }

  .nav-item {
    font-size: 0.8em;
    margin-right: 10px;

  }

  .nav-bar nav ul li a {
    margin-right: 10px;
  }
}